import React, { useState, useEffect } from "react";
import QrScanner from "react-qr-scanner";
import api from "../../Services/axios";
import InputText from "../Input/InputText";
import { FaQrcode } from "react-icons/fa";
import CheckBoxList from "../List/CheckBoxList";
import { useAuthContext } from "../../Auth/useAuthContext";
import { useNavigate } from "react-router-dom";

type Acompanhante = {
  nome: string;
  sobrenome: string;
  cpf: string;
  nacionalidade?: string;
  sexo?: string;
  data_nascimento?: string | null;
  _id: string;
};

type DadosViagem = {
  _id: string;
  visitante: Acompanhante;
  emergencia_telefone?: string;
  emergencia_nome?: string;
  acompanhante: Acompanhante[];
};

const QRCodeReader: React.FC = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const [data, setData] = useState<string | null>(null);
  const [idViagem, setIdViagem] = useState<string>("");
  const [identificacao, setIdentificacao] = useState<string | any>();
  const [mensagem, setMensagem] = useState<string | null>("");
  const [showScanner, setShowScanner] = useState<boolean>(false);
  const [cameraError, setCameraError] = useState<string | null>(null);
  const [lista, setLista] = useState<Acompanhante[]>([]);
  const [listaSelector, setListaSelector] = useState<Acompanhante[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dados, setDados] = useState<DadosViagem | null>(null);

  const handleScan = (result: any) => {
    if (result && typeof result === "object" && result.text) {
      setData(result.text);
      buscaDadosViagam(result.text);
      toggleScanner();
      console.log("QR Code data:", result.text);
    }
  };

  const limparIdentificacao = async (id: string) => {
    return id.replace(/[.\-\/]/g, "");
  };

  useEffect(() => {
    const fetchViagem = async () => {
      if (identificacao && identificacao.length === 11) {
        try {
          const idLimpo = await limparIdentificacao(identificacao);
          const { data: res } = await api.get(`/viagembycpf/${idLimpo}`);

          if (res.message) {
            setMensagem(res.message);
            return;
          }
          setIdViagem(res.viagem._id);
          setMensagem("Viagem localizada!");
        } catch (error) {
          console.error("Erro ao buscar viagem: ", error);
        }
      } else {
        setMensagem("");
      }
    };

    fetchViagem();
  }, [identificacao]);

  useEffect(() => {
    buscaDadosViagam(idViagem);
  }, [idViagem]);

  const buscaDadosViagam = async (idViagem: string) => {
    if (idViagem) {
      try {
        const { data: response } = await api.get(`/viagemNew/${idViagem}`);

        if (!response.message) {
          setDados(response.viagem.json_result);

          const acompanhantes = response.viagem.json_result.acompanhante ?? [];

          const visitante = {
            nome: response.viagem.json_result.visitante.nome,
            sobrenome: response.viagem.json_result.visitante.sobrenome,
            cpf: response.viagem.json_result.visitante.identificacao,
            nacionalidade: response.viagem.json_result.visitante.nacionalidade,
            sexo: response.viagem.json_result.visitante.sexo,
            data_nascimento: null,
            _id: response.viagem.json_result.visitante._id,
          };

          if (acompanhantes.length > 0) {
            setListaSelector([...acompanhantes, visitante]);
            return;
          }

          setListaSelector([visitante]);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error("Erro ao buscar viagem: ", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  function onCheckIn(lista: Acompanhante[]) {
    const aux: Acompanhante[] = lista.map((item) => ({
      idViagem: dados?._id || "",
      nome: item.nome,
      sobrenome: item.sobrenome,
      identificacao: item.cpf, // Assumindo que identificacao é o cpf
      cpf: item.cpf, // Adiciona a propriedade cpf aqui
      emergencia_telefone: dados?.emergencia_telefone || "",
      emergencia_nome: dados?.emergencia_nome || "",
      nacionalidade: item.nacionalidade,
      checkin: true,
      checkout: false,
      _id: (Math.random() * 3).toString(36).substring(2),
    }));

    if (aux) {
      salvarCheckIn(aux);
    }
  }

  async function salvarCheckIn(lista: Acompanhante[]) { 
    if (user) {
      try {
        const response = await api.post(`/atividade/addpassageiros`, {
          listaPassageiros: lista,
          colaborador: user.id,
        });
        console.log(response.data.message);
      } catch (error: any) {
        console.error(error.response.data.message);
      }
    }
  }

  const handleError = (err: any) => {
    console.error(err);
    setCameraError(
      "Erro ao acessar a câmera. Verifique as permissões ou use outro dispositivo."
    );
  };

  const toggleScanner = () => {
    if (
      navigator.mediaDevices &&
      typeof navigator.mediaDevices.getUserMedia === "function"
    ) {
      setShowScanner((prevShowScanner) => !prevShowScanner);
      setCameraError(null);
    } else {
      setCameraError(
        "Este dispositivo não suporta a funcionalidade de câmera."
      );
    }
  };

  if (user?.segmento !== "Colaborador") {
    return <div style={{ textAlign: "center" }}></div>;
  }

  return (
    <div style={styles.container}>

      <div
        style={{ cursor: "pointer", color: "#0168fa", padding: "10px",  fontFamily: "Poppins-Medium",fontSize:'12px' }}
        onClick={() => navigate(-1)}
      >
        <span>Voltar</span>
      </div>

      <p
        style={{
          textAlign: "center",
          paddingTop: "10px",
          fontSize: "20px",
          fontWeight: "bold",
          fontFamily: "Poppins-Regular",
          color:"#222222"
        }}
      >
        Vamos dar início às atividades!
      </p>

      <p style={{ textAlign: "center", paddingTop: "10px", fontFamily: "Poppins-Medium", fontSize:'14px', color:'#6a6a6a' }}>
        Alinhe o código QR dentro do quadro para digitalizar
      </p>

      {showScanner && !cameraError && (
        <div style={styles.button}>
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "100%" }}
          />
        </div>
      )}

      {cameraError && (
        <p
          style={{
            color: "red",
            textAlign: "center",
            fontFamily: "Poppins-Regular",
          }}
        >
          {cameraError}
        </p>
      )}

      <div
        style={{

          flexDirection: 'column',
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5%",
        }}
      >
        <div style={styles.button} onClick={toggleScanner}>
          <FaQrcode size={100} />
        </div>

        <div>
            <p
              style={{
                textAlign: "center",
                paddingTop: "10px",
                fontSize: "12px",
                fontFamily: "Poppins-Medium",
                color:"#008AC0"
              }}
            >
              Clique para Scanear
            </p>
          </div>
          
      </div>

      <div>
            <p
              style={{
                textAlign: "center",
                paddingTop: "10px",
                fontSize: "13px",
                fontFamily: "Poppins-Medium",
                color:"#222222"
              }}
            >
              Escaneie o QR Code ou busque pelo visitante!
            </p>
          </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5%",
        }}
      >
        <InputText
          id="identificacao"
          title=""
          type="number"
          placeholder="CPF do Visitante"
          value={identificacao}
          onchange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setIdentificacao(e.target.value)
          }
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5px",
          textAlign: "center",
          fontSize: "12px",
          fontWeight: "bold",
          color: "#c13515",
          fontFamily: "Poppins-Regular",
        }}
      >
        {mensagem}
      </div>

      {listaSelector.length > 0 && (
        <CheckBoxList
          multiple
          options={listaSelector}
          onchange={(op) => onCheckIn(op as Acompanhante[])}
        />
      )}
    </div>
  );
};

export default QRCodeReader;

const styles = {
  container: {
    width: "100% auto",
    flex: 1,
    backgroundColor: "#fff",
    padding: "5%",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    marginTop: "5%",
    cursor: "pointer",
    
    
  },
};

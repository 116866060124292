import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2pdf from 'html2pdf.js';
import { FaQrcode } from "react-icons/fa";
import { useAuthContext } from "../../Auth/useAuthContext";
import api from "../../Services/axios";
import QRCode from 'react-qr-code';

interface Passageiro {
    nome: string;
    sobrenome: string;
    identificacao: string;
    nacionalidade: string;
    emergencia_nome: string;
    emergencia_telefone: string;
}

interface Colaborador {
    nome: string;
    funcao: string;
    inscricao: string;
    usuario_email: string;
    telefone: string;
    cpf: string;
}

interface Atividade {
    veiculo_nome: string;
    veiculo_identificacao: string;
    veiculo_capacidade: number;
    veiculo_portoInscricao: string;
    empresa_rasao_social: string;
    empresa_cnpj: string;
    empresa_telefone: string;
    empresa_logradouro: string;
    empresa_email: string;
    local_embarque_nome: string;
    destino_viagem: string;
    local_chegada_nome: string;
    destino: string;
    dataInicio: string;
    _id: string;
    empresa_id: string;
    passageiros: Passageiro[];
    duracao: string;
}

interface Dados {
    atividade: Atividade;
    colaborador: Colaborador[];
}

const TermoAtividades: React.FC = () => {
    const [dados1, setDados1] = useState<Dados | undefined>(undefined);
    const navigate = useNavigate();
    const { user, showLoading, hideLoading } = useAuthContext();

    const location = useLocation();

    const { idAtividade } = location.state as { idAtividade: string;};

    useEffect(() => {
        showLoading();
        
        return () => hideLoading();
    }, [showLoading, hideLoading]);

    useEffect(() => {
        (async () => {
            showLoading();
            try{   

                const { data: response } = await api.get(`/atividade/detalhe/${idAtividade}/${user?.id}`)
                //console.log("Dados retornados: ", response)     

                setDados1(response);
                hideLoading();
            }
            catch(error){
                //setIsLoading(false);
                console.log("Caiu no erro: ", error)
                //navigation.goBack();
            };



        })();
    }, [location]) 

    async function gerarLista(lista: Passageiro[]): Promise<string> {
        return lista.map(item => `
            <tr>
                <td style="width: 22.7254%; font-size:13px;">${item.nome} ${item.sobrenome}</td>
                <td style="width: 17.2746%; font-size:13px;">${item.identificacao}</td>
                <td style="width: 20%; font-size:13px;">${item.nacionalidade}</td>
                <td style="width: 20%; font-size:13px;">${item.emergencia_nome}</td>
                <td style="width: 20%; font-size:13px;">${item.emergencia_telefone}</td>
            </tr>
        `).join("");
    }

    function formatarDataParaPTBR(dataISO: string): string {
        const data = new Date(dataISO);
        return data.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    // Função para enviar o PDF para a API
    async function enviarPDF(pdfUri: string) {
        try {
            // Usando FileReader para ler o conteúdo do arquivo
            const file = new File([pdfUri], `termo_Atividade_${dados1?.atividade._id}_Empresa_${dados1?.atividade.empresa_id}.pdf`, { type: 'application/pdf' });
            const reader = new FileReader();
    
            reader.onloadend = async () => {
                // Cria um FormData e adiciona o arquivo PDF
                const formData = new FormData();
                formData.append('arquivo', file);  // Agora, adicionamos um File em vez de um objeto com 'uri'
    
                // Adicionando o `empresa_id` ao FormData
                const empresaId = dados1?.atividade.empresa_id ?? "defaultEmpresaId";  // Valor default caso o `empresa_id` seja undefined
                formData.append('empresa_id', empresaId);

                console.log("Enviando email: " + formData.toString());
    
                // Faz a requisição POST para enviar o PDF
                const response = await api.post('/envio/termos/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
    
                // Lida com a resposta da API
                if (response.status === 200) {
                    console.log('Sucesso', 'PDF enviado com sucesso!');
                } else {
                    console.log('Erro', 'Falha ao enviar o PDF.');
                }
            };
    
            reader.readAsDataURL(file);  // Converte o arquivo para base64, se necessário
    
        } catch (error) {
            console.error('Erro ao enviar o PDF:', error);
            console.log('Erro', 'Ocorreu um erro ao enviar o PDF.');
        }
    }
    

    async function createPDF(item: number): Promise<void> {
        if (!dados1) return;
    
        const lista = await gerarLista(dados1.atividade.passageiros);
        const dataInicioPTBR = formatarDataParaPTBR(dados1.atividade.dataInicio);
    
        const htmlContent = `
                  <!DOCTYPE html>
                    <html>
                        <head>
                            <meta charset="UTF-8">
                            <style>
                                /* Estilos gerais para garantir que o layout seja respeitado */
                                body {
                                    font-family: Arial, sans-serif;
                                    margin: 0;
                                    padding: 20px;
                                    background-color: #f9f9f9;
                                }

                                /* Container principal para garantir que o conteúdo é distribuído corretamente */
                                .page {
                                    display: flex;
                                    flex-direction: column;
                                    min-height: 100vh; /* Garante que o rodapé fica no fundo */
                                }

                                /* Tabelas */
                                table {
                                    width: 100%;
                                    border-collapse: collapse;
                                    margin-top: 20px;
                                }

                                th, td {
                                    padding: 8px;
                                    border: 1px solid #ddd;
                                    text-align: left;
                                }

                                th {
                                    background-color: #f4f4f4;
                                }

                                /* Estilo para os containers de dados */
                                .content-container {
                                    display: flex;
                                    gap: 20px;
                                    margin-bottom: 20px;
                                }

                                .content-container > div {
                                    flex: 1;
                                    padding: 10px;
                                    border: 1px solid #ddd;
                                }

                                .content-container > div h2 {
                                    font-size: 16px;
                                    margin-bottom: 10px;
                                }

                                /* Rodapé */
                                .responsabilidade {
                                    text-align: center;
                                    font-size: 14px;
                                    line-height: 1.5;
                                    padding: 10px 0;
                                    background-color: #f0f0f0;
                                    border-top: 1px solid #ccc;
                                    margin-top: 20px;
                                    /* Remover position: absolute e garantir que o rodapé se mova com o conteúdo */
                                    margin-bottom: 20px;
                                }
                                .tripulante-container {
                                    display: flex;
                                    gap: 20px;
                                    padding: 10px;
                                }

                                .tripulante-container > div {
                                    flex: 1;
                                }

                                /* Força uma quebra de página antes do rodapé quando necessário */
                                .page-break {
                                    page-break-before: always;
                                    break-before: page;
                                }

                            </style>
                        </head>
                        <body>
                            <!-- Primeira Página -->
                            <div class="page">
                                <div class="content-container">
                                    <div>
                                        <h2>Dados da Embarcação</h2>
                                        <p><strong>Nome da Embarcação:</strong> ${dados1.atividade.veiculo_nome}</p>
                                        <p><strong>Nº de Inscrição:</strong> ${dados1.atividade.veiculo_identificacao}</p>
                                        <p><strong>Capacidade:</strong> ${dados1.atividade.veiculo_capacidade} pessoas</p>
                                        <p><strong>Porto de Inscrição:</strong> ${dados1.atividade.veiculo_portoInscricao}</p>
                                    </div>
                                    <div>
                                        <h2>Representante/Empresa</h2>
                                        <p><strong>Nome:</strong> ${dados1.atividade.empresa_rasao_social}</p>
                                        <p><strong>CNPJ/CPF:</strong> ${dados1.atividade.empresa_cnpj}</p>
                                        <p><strong>Telefone:</strong> ${dados1.atividade.empresa_telefone ? dados1.atividade?.empresa_telefone : 'N/A'}</p>
                                        <p><strong>Endereço:</strong> ${dados1.atividade.empresa_logradouro}</p>
                                        <p><strong>Email:</strong> ${dados1.atividade.empresa_email ? dados1.atividade.empresa_email : 'N/A'}</p>
                                    </div>
                                </div>

                                <!-- Dados da Partida e Comandante -->
                                <div class="content-container">
                                    <div>
                                        <h2>Dados da Partida/Chegada</h2>
                                        <p><strong>Local de Saída da Embarcação:</strong> ${dados1.atividade.local_embarque_nome}</p>
                                        <p><strong>Destino da Viagem/Roteiro da Embarcação:</strong> ${dados1.atividade.destino_viagem ? dados1.atividade.destino_viagem : 'N/A'}</p>
                                        <p><strong>Local de Chegada da Embarcação:</strong> ${dados1.atividade.local_chegada_nome ? dados1.atividade.local_chegada_nome : 'N/A'}</p>
                                    </div>
                                    <div>
                                        <h2>Dados do Comandante</h2>
                                        <p><strong>Nome:</strong> ${dados1.colaborador[0].nome}</p>
                                        <p><strong>Categoria:</strong> ${dados1.colaborador[0].funcao}</p>
                                        <p><strong>Nº de Inscrição da CIR:</strong> ${dados1.colaborador[0].inscricao}</p>
                                        <p><strong>Email:</strong> ${dados1.colaborador[0].usuario_email}</p>
                                        <p><strong>Telefone:</strong> ${dados1.colaborador[0].telefone ? dados1.colaborador[0].telefone : 'N/A'}</p>
                                    </div>
                                </div>

                                <!-- Lista de Passageiros -->
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>CPF</th>
                                            <th>Nacionalidade</th>
                                            <th>Emergência</th>
                                            <th>Telefone</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        ${lista}
                                    </tbody>
                                </table>

                                <!-- Texto de Responsabilidade - Rodapé de todas as páginas-->
                                <div class="responsabilidade">
                                    Estou ciente de que responderei administrativa, civil e penalmente pelos eventuais <br>
                                    dados incorretos constantes da presente declaração <br>

                                    <!-- Local e Data -->
                                    <div style="display: flex; justify-content: center; gap: 20px;">
                                        <div><strong>Local: ${dados1.atividade.destino}</strong></div>
                                        <div><strong>Data: ${dataInicioPTBR}</strong></div>
                                    </div>

                                    <br>
                                    Eu Comandante/Representante me declaro responsável pela atividade em andamento
                                </div>

                            </div>
                            
                            <!-- Quebra de página para segunda página -->
                            <div class="page-break"></div>
                            
                            <!-- Ultima Página -->
                            <div class="page">
                                <div style="padding: 10px;">
                                    <h2>TERMO DE ANUÊNCIA E ACEITE CONJUNTO</h2>
                                    <p>Os abaixo assinados, passageiros da embarcação ${dados1.atividade.veiculo_nome}, atestam anuência e aceite em relação ao uso de equipamentos de segurança e orientações sobre as regras.</p>
                                    <p><strong>Data do Passeio:</strong> ${dataInicioPTBR}</p>
                                    <p><strong>Saída:</strong> ${dataInicioPTBR}</p>
                                    <p><strong>Retorno:</strong> ${dataInicioPTBR}</p>

                                    <p>Atestam anuência e aceite em relação ao uso de equipamentos de segurança e orientações sobre as
                                        regras de entrada nos atrativos cânions de capitólio cânions vale dos tucanos e morro dos cabritos, 
                                        bem como orientação acerca dos riscos que envolve qualquer tipo de turismo de natureza.
                                    </p>
                                    <h2>DAS REGRAS DE USO E DOS EQUIPAMENTOS DE SEGURANÇA</h2>
                                    <p>
                                        Em caráter temporário, até que se finalize todo plano de monitoramento dos atrativos cânions de 
                                        capitólio e cânions Vale dos Tucanos e Morro dos Cabritos, a entrada nos atrativos terá um fluxo reduzido 
                                        de embarcações não sendo autorizado à aproximação dos paredões e parada da embarcação. Para que 
                                        seja minimizado todo e qualquer risco, ainda que mínimo, durante o trajeto no atrativo utilizaremos 
                                        capacete de proteção e colete salva vidas. O local será vistoriado todos os dias por geólogo e no caso de 
                                        chuva a entrada no atrativo será suspensa até nova vistoria. Durante todo o dia equipe de profissionais do 
                                        Município estarão no local para controle do fluxo e apoio aos visitantes. Considerando que todo turismo 
                                        de natureza envolve naturalmente algum tipo de risco, todo trabalho e normas expostas visam preservar 
                                        a segurança e proporcionar ao visitante um turismo responsável e sustentável.
                                    </p>

                                    <h2>NOME DOS TRIPULANTES</h2>

                                    <div class="tripulante-container">
                                        <div>
                                            <p><strong>Nome Completo:</strong> ${dados1.colaborador[0].nome}</p>
                                        </div>
                                        <div>
                                            <p><strong>CPF:</strong> ${dados1.colaborador[0].cpf}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </body>
                    </html>
                `;
    
        const element = document.createElement("div");
        element.innerHTML = htmlContent;
        document.body.appendChild(element);
    
        const options = {
            filename: `termo_Atividade_${dados1.atividade._id}_Empresa_${dados1.atividade.empresa_id}.pdf`,
            html2canvas: { scale: 2 }, // Melhora a qualidade da renderização
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
    
        if (item === 1) {
            html2pdf()
                .from(element)
                .set(options)
                .toPdf()
                .get('pdf')
                .then((pdf: jsPDF) => {
                    const pdfBlob = pdf.output('blob');
                    const url = URL.createObjectURL(pdfBlob);
                    enviarPDF(url);  // Envia o PDF
                });
        } else {
            html2pdf()
                .from(element)
                .set(options)
                .save();  // Baixa o PDF
        }
    
        document.body.removeChild(element);  // Remove o elemento do DOM após gerar o PDF
    }
    
    

    if (!dados1) {
        return <div></div>; // Exibe uma mensagem de carregamento até que `dados1` seja definido
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px"}}>
            <div style={{paddingBottom: "100px" }}>

                <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                    {/* Substituir a ação de voltar com uma navegação ou outra lógica */}
                    <span>← Voltar</span>
                </div>

                <div style={{ textAlign: "center" }}>

                    <QRCode
                        value={String(dados1.atividade._id)}
                        size={130}  />

                    <p style={{ fontSize: 20, color: "#0D2041", textTransform: "capitalize",textAlign: "center"  }}>
                        {dados1.atividade.empresa_rasao_social}
                    </p>
                    <p style={{ fontSize: 14, color: "#6F7399", textAlign: "center"  }}>
                        {formatarDataParaPTBR(dados1.atividade.dataInicio)}
                    </p>
                </div>

                <div style={{ marginTop: "10px", borderBottom: "1px solid #D5D6DA", textAlign: "center", padding: "3%" }}>
                    <p style={{ fontSize: 16, color: "#052937", textTransform: "capitalize",textAlign: "center"  }}>
                        Quantidade de Pessoas
                    </p>
                    <p style={{ fontSize: 20, color: "#052937", marginTop: "2%" }}>
                        {dados1.atividade.passageiros.length} Pessoas
                    </p>
                </div>

                <div style={styles.box3}>

                    <p style={{ fontSize: 16, color: "#052937", textTransform: "capitalize", textAlign: "center" }}>Nome da Embarcação/Veículo</p>
                    <p style={{  fontSize: 20, color: "#052937", textTransform: "capitalize", textAlign: "center" }}>
                        {dados1.atividade.veiculo_nome}
                    </p>

                    <div style={styles.box3sub}>
                        <div>
                            <p style={{padding: "3%"}}> Capacidade: {dados1.atividade.veiculo_capacidade} pessoas</p>

                            <div style={{display: "flex", flexDirection: "row", padding: "3px"}}>
                                <div style={styles.bolinha}></div>
                                <p style={styles.subtext}>Ponto de Embarque: {dados1.atividade.local_embarque_nome}</p>
                            </div>
                            
                            <div style={{display: "flex", flexDirection: "row", padding: "3px"}}>
                                <div style={styles.bolinha}></div>
                                <p style={styles.subtext}>Proprietário: {dados1.atividade.empresa_rasao_social}</p>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", padding: "3px"}}>
                                <div style={styles.bolinha}></div>
                                <p style={styles.subtext}>Duração: {dados1.atividade.duracao}</p>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div style={{borderBottom: "0.6px", height: "13%", flexDirection: "row", padding: "3%"}}>
                    <div style={styles.subbox}>
                        <p style={styles.text2}>Endereço</p>
                        <p style={styles.text4}>{dados1.atividade.empresa_logradouro}</p>
                    </div>
                    <div style={styles.subbox}>
                        <p style={styles.text2}>CNPJ</p>
                        <p style={styles.text4}>{dados1.atividade.empresa_cnpj}</p>
                    </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", borderBottom: "0.6px", height: "13%", padding: "3%" }}>

                    <div style={{ width: "400px", display: "flex", marginTop: "10px", textAlign: "center" , alignContent: "center", justifyContent: "center" }}>
                        <button
                            style={{
                                width: "300px",
                                padding: "10px 20px",
                                fontSize: "16px",
                                cursor: "pointer",
                                backgroundColor: "#F74F4F",
                                color: "#fff",
                                border: "none",
                                borderRadius: "10px",
                            }}
                            onClick={() => createPDF(0)}
                        >
                            Gerar Termo PDF
                        </button>
                    </div>

                    <div style={{ width: "400px", display: "flex", marginTop: "10px", textAlign: "center" , alignContent: "center", justifyContent: "center"}}>
                        <button
                            style={{
                                width: "300px",
                                padding: "10px 20px",
                                fontSize: "16px",
                                cursor: "pointer",
                                backgroundColor: "#4f98f7",
                                color: "#fff",
                                border: "none",
                                borderRadius: "10px",
                            }}
                            onClick={() => createPDF(1)}
                        >
                            Enviar termo para Órgão
                        </button>
                    </div>

                </div>


            </div>
        </div>
    );
};

export default TermoAtividades;

const styles = {
    container: {
        flex: 1,
        backgroundColor: '#fff',
        padding: '5%',
    },
    scrollContainer: {
        paddingBottom: 100, // Adicione padding inferior para evitar a tab bar
    },
    box1: {
        alignItems: "center"
    },
    box2: {
        marginTop: 10,
        borderTopWidth: .6,
        borderBottomWidth: .6,
        borderColor: "#D5D6DA",
        alignItems: "center",
        justifyContent: "center",
        padding: '3%',
        height: "10%"
    },
    box3: {
        alignItems: "center",
        justifyContent: "center",
        padding: "3%",
        borderBottomWidth: .6,
        borderColor: "#D5D6DA",
    },
    box3sub: {
        marginTop: '3%',
        minHeight: 104,
        minWidth: "100%",
        borderRadius: 5,
        backgroundColor: "rgba(91, 99, 169, 0.1)",
        alignItems: "center",
        justifyContent: "center",
    },
    box4: {
        borderBottom: "0.6px solid #D5D6DA", // Usando a sintaxe abreviada corretamente
        height: '13%',
        flexDirection: "row",
        padding: '3%',
    },
    
    box5: {
        marginTop: 10,
        borderBottomWidth: .6,
        borderColor: "#D5D6DA",
        alignItems: "center",
        justifyContent: "center",
        padding: '3%',
        height: "10%"
    },
    empresa: {
        fontSize: 20,
        textTransform: "capitalize",
        color: '#0D2041'
    },
    text2: {
        fontSize: 14,
        color: "#6F7399",
    },
    text3: {
        fontSize: 20,
        color: "#052937",
        textTransform: "capitalize",
        marginTop: "2%"
    },
    text5: {
        fontSize: 16,
        color: "#052937",
        textTransform: "capitalize",
        marginTop: "2%"
    },
    containerSubText: {
        flexDirection: "row" as "row" | "column",
        alignItems: "center" as "flex-start" | "flex-end" | "center" | "baseline" | "stretch", // Tipo específico para alignItems
    },
    subtext: {
        color: '#606487',
        fontSize: 12,
    },
    bolinha: {
        marginLeft: 15,
        marginRight: 10,
        backgroundColor: '#878DC5',
        height: 4,
        width: 4,
        borderColor: '#878DC5',
        borderRadius: 10,
        padding: 5
    },
    relatorio: {
        textAlign: "center",
        fontSize: 14,
        color: "#052937",
        marginTop: "3%"
    },
    subbox: {
        borderColor: 'red',
        borderWidth: 0,
        flex: 1
    },
    text4: {
        color: '#052937',
        fontSize: 13,
        marginRight: 5
    }
};

import api from '../Services/axios';


export const BuscaDadosEmpresa_By_user_id = async ( id : string ) =>{

    const { data : res } = await api.get(`/colaboradorempresa/${id}`);
    if(res){
        try{

            return res

        }catch(error){
                return "Falha ao listar Empresas tente mais tarde."
            }
    }
    return undefined;
    
}
// features/atividade/atividadeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Empresa {
  _id: string;
  cnpj: string;
  rasao_social: string;
}

interface Usuario {
  _id: string;
  nome: string;
}

interface Veiculo {
  nome: string;
  destino: string;
  capacidade: string;
}

interface Passageiro {
  _id: string;
  cpf: string;
  nome: string;
  checkin: boolean;
  checkout: boolean;
  idViagem: number;
  sobrenome: string;
  identificacao: string;
  nacionalidade: string;
  emergencia_nome: string;
  emergencia_telefone: string;
  idAtividade: string;
}

interface AtividadeHora {
  _id: string;
  ativa: number;
  duracao: string;
  empresa: Empresa;
  usuario: Usuario;
  veiculo: Veiculo;
  dataInicio: string;
  passageiros: Passageiro[];
  destino_viagem: string;
}

interface AtividadeState {
  _id: string;
  titulo?: string | any;
  hora: AtividadeHora[];
}

const initialState: AtividadeState = {
  _id: '',
  titulo: '',
  hora: [],
};

const atividadesEmpresaReducer = createSlice({
  name: 'atividadeEmpresa',
  initialState,
  reducers: {
    setAtividade: (state, action: PayloadAction<AtividadeState>) => {
      return action.payload;
    },
    setPassageiros: (state, action: PayloadAction<{ atividadeId: string; passageiros: Passageiro[] }>) => {
      const atividade = state.hora.find(h => h._id === action.payload.atividadeId);
      if (atividade) {
        atividade.passageiros = action.payload.passageiros;
      }
    },
    addPassageiro: (state, action: PayloadAction<{ atividadeId: string; passageiro: Passageiro }>) => {
      const atividade = state.hora.find(h => h._id === action.payload.atividadeId);
      if (atividade) {
        atividade.passageiros.push(action.payload.passageiro);
      }
    },
    removePassageiro: (state, action: PayloadAction<{ atividadeId: string; passageiroId: string }>) => {
      const atividade = state.hora.find(h => h._id === action.payload.atividadeId);
      if (atividade) {
        atividade.passageiros = atividade.passageiros.filter(p => p._id !== action.payload.passageiroId);
      }
    },
    updatePassageiro: (state, action: PayloadAction<{ atividadeId: string; passageiro: Passageiro }>) => {
      const atividade = state.hora.find(h => h._id === action.payload.atividadeId);
      if (atividade) {
        const index = atividade.passageiros.findIndex(p => p._id === action.payload.passageiro._id);
        if (index !== -1) {
          atividade.passageiros[index] = action.payload.passageiro;
        }
      }
    },
  },
});

export const { setAtividade, setPassageiros, addPassageiro, removePassageiro, updatePassageiro } = atividadesEmpresaReducer.actions;
export default atividadesEmpresaReducer.reducer;

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Registrando componentes do Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

  const FaixaetariaIdade: React.FC = () => {

    const data = {
      labels: [],
      datasets: [
        {
          label: 'Série 1',
          data: [30, 20, 50, 40], // Valores associados às categorias
          backgroundColor: ['#ff6384', '#36a2eb', '#cc65fe', '#ffce56'], // Cores das fatias
          hoverOffset: 4, // Efeito de destaque ao passar o mouse
        },
      ],
    };

    return (    
        <div className="col-md-6 col-lg-4 col-xl-3 mg-t-10 mg-lg-t-0">
            <div className="card">
              <div className="card-header">
                <h6 className="mg-b-0">Faixa etária de Idade</h6>
              </div>
              <div className="card-body pd-lg-25">
                <div style={{ width: '100%', height: '188px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Doughnut data={data} />
                </div>
              </div>
              <div className="card-footer pd-20">
                <div className="row">
                  <div className="col-6">
                    <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-5">0 a 17 anos</p>
                    <div className="d-flex align-items-center">
                      <div className="wd-10 ht-10 rounded-circle bg-pink mg-r-5"></div>
                      <h5 className="tx-normal tx-rubik mg-b-0">1,320 <small className="tx-color-04">25%</small></h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">18 a 34 anos</p>
                    <div className="d-flex align-items-center">
                      <div className="wd-10 ht-10 rounded-circle bg-primary mg-r-5"></div>
                      <h5 className="tx-normal tx-rubik mg-b-0">987 <small className="tx-color-04">20%</small></h5>
                    </div>
                  </div>
                  <div className="col-6 mg-t-20">
                    <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">35 a 49 anos</p>
                    <div className="d-flex align-items-center">
                      <div className="wd-10 ht-10 rounded-circle bg-teal mg-r-5"></div>
                      <h5 className="tx-normal tx-rubik mg-b-0">2,010 <small className="tx-color-04">30%</small></h5>
                    </div>
                  </div>
                  <div className="col-6 mg-t-20">
                    <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">Acima de 50 anos</p>
                    <div className="d-flex align-items-center">
                      <div className="wd-10 ht-10 rounded-circle bg-orange mg-r-5"></div>
                      <h5 className="tx-normal tx-rubik mg-b-0">1,054 <small className="tx-color-04">25%</small></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
}

export default FaixaetariaIdade;
import React, {useMemo} from 'react';
import { Chart, AxisOptions, ChartOptions } from 'react-charts';

// Definindo os tipos para os dados do gráfico
interface DataPoint {
  primary: string;  // O eixo X será de tipo string (nomes dos meses)
  secondary: number; // O eixo Y será de tipo número (valores)
}

interface Series {
  label: string; // O nome de cada série (Série 1, Série 2, etc.)
  data: DataPoint[]; // Os pontos de dados para cada série
}

const PerfilGenero: React.FC = () => {

  const data: Series[] = useMemo(
    () => [
      {
        label: 'Homens',
        data: [
          { primary: 'Jan', secondary: 30 },
          { primary: 'Fev', secondary: 40 },
          { primary: 'Mar', secondary: 50 },
          { primary: 'Abr', secondary: 50 },
          { primary: 'Mai', secondary: 50 },
          { primary: 'Jun', secondary: 50 },
          { primary: 'Jul', secondary: 50 },
          { primary: 'Ago', secondary: 50 },
          { primary: 'Set', secondary: 50 },
          { primary: 'Out', secondary: 50 },
          { primary: 'Nov', secondary: 50 },
          { primary: 'Dez', secondary: 50 },
        ],
      },
      {
        label: 'Mulhers',
        data: [
          { primary: 'Jan', secondary: 30 },
          { primary: 'Fev', secondary: 20 },
          { primary: 'Mar', secondary: 50 },
          { primary: 'Abr', secondary: 50 },
          { primary: 'Mai', secondary: 50 },
          { primary: 'Jun', secondary: 50 },
          { primary: 'Jul', secondary: 40 },
          { primary: 'Ago', secondary: 50 },
          { primary: 'Set', secondary: 50 },
          { primary: 'Out', secondary: 60 },
          { primary: 'Nov', secondary: 50 },
          { primary: 'Dez', secondary: 20 },
        ],
      },
      {
        label: 'Crianças',
        data: [
          { primary: 'Jan', secondary: 30 },
          { primary: 'Fev', secondary: 20 },
          { primary: 'Mar', secondary: 50 },
          { primary: 'Abr', secondary: 50 },
          { primary: 'Mai', secondary: 30 },
          { primary: 'Jun', secondary: 50 },
          { primary: 'Jul', secondary: 50 },
          { primary: 'Ago', secondary: 50 },
          { primary: 'Set', secondary: 40 },
          { primary: 'Out', secondary: 50 },
          { primary: 'Nov', secondary: 50 },
          { primary: 'Dez', secondary: 50 },
        ],
      },
      {
        label: 'Outros',
        data: [
          { primary: 'Jan', secondary: 30 },
          { primary: 'Fev', secondary: 20 },
          { primary: 'Mar', secondary: 50 },
          { primary: 'Abr', secondary: 50 },
          { primary: 'Mai', secondary: 50 },
          { primary: 'Jun', secondary: 80 },
          { primary: 'Jul', secondary: 50 },
          { primary: 'Ago', secondary: 50 },
          { primary: 'Set', secondary: 50 },
          { primary: 'Out', secondary: 50 },
          { primary: 'Nov', secondary: 50 },
          { primary: 'Dez', secondary: 70 },
        ],
      },
    ],
    []
  );

 // Configurando o eixo primário (eixo X)
 const primaryAxis = React.useMemo<AxisOptions<DataPoint>>(
  () => ({
    getValue: (datum: DataPoint) => datum.primary, // Mapeia o valor do eixo X
  }),
  []
);

// Configurando o eixo secundário (eixo Y)
const secondaryAxis = React.useMemo<AxisOptions<DataPoint>>(
  () => ({
    getValue: (datum: DataPoint) => datum.secondary, // Mapeia o valor do eixo Y
    elementType: 'bar', // Definindo o gráfico de barras
    min: 0, // Definindo o mínimo valor do eixo Y
  }),
  []
);

  // Função para personalizar o estilo de cada série com 4 cores diferentes
  const getSeriesStyle = React.useCallback((series: any) => {
    // Definindo as cores das séries
    const colors = ['#00cccc', '#fd7e14', '#f10075', '#36a2eb']; // Array com 4 cores
    return {
      fill: colors[series.index % colors.length], // Ciclando entre as cores definidas
    };
  }, []);

// Opções do gráfico
const options: ChartOptions<DataPoint> = {
  data,              // Dados do gráfico
  primaryAxis,       // Eixo X
  secondaryAxes: [secondaryAxis],  // Eixo Y
  tooltip: true,     // Ativa o tooltip
  getSeriesStyle,
  
};

  return (

          <div className="col-lg-8 col-xl-9">
            <div className="card">
              <div className="card-header bd-b-0 pd-t-20 pd-lg-t-25 pd-l-20 pd-lg-l-25 d-flex flex-column flex-sm-row align-items-sm-start justify-content-sm-between">
                <div>
                  <h6 className="mg-b-5">Perfil de Gênero</h6>
                  <p className="tx-12 tx-color-03 mg-b-0">Público ao qual os usuários pertenciam no intervalo de datas atual.</p>
                </div>
                <div className="btn-group mg-t-20 mg-sm-t-0">
                  <button className="btn btn-xs btn-white btn-uppercase">Dia</button>
                  <button className="btn btn-xs btn-white btn-uppercase">Semana</button>
                  <button className="btn btn-xs btn-white btn-uppercase active">Mês</button>
                </div>
              </div>

              <div className="card-body pd-lg-25">
                <div className="row align-items-sm-end">

                  <div className="col-lg-7 col-xl-8">


                  <div style={{ width: '100%', height: '300px' }}>
                    <Chart options={options} />
                  </div>


                  </div>
                  
                  {/* Dados lateral */}
                  <div className="col-lg-5 col-xl-4 mg-t-30 mg-lg-t-0">
                    <div className="row">
                     
                      <div className="col-sm-6 col-lg-12">
                        <div className="d-flex align-items-center justify-content-between mg-b-5">
                          <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Homens</h6>
                          <span className="tx-10 tx-color-04">65% comparado a</span>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mg-b-5">
                          <h5 className="tx-normal tx-rubik lh-2 mg-b-0">13,596</h5>
                          <h6 className="tx-normal tx-rubik tx-color-03 lh-2 mg-b-0">20,000</h6>
                        </div>
                        <div className="progress ht-4 mg-b-0 op-5">
                          <div className="progress-bar bg-teal wd-65p" role="progressbar" aria-valuenow={65} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-12 mg-t-30 mg-sm-t-0 mg-lg-t-30">

                        <div className="d-flex align-items-center justify-content-between mg-b-5">
                          <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Mulheres</h6>
                          <span className="tx-10 tx-color-04">45% comparado a</span>
                        </div>

                        <div className="d-flex justify-content-between mg-b-5">
                          <h5 className="tx-normal tx-rubik mg-b-0">83,123</h5>
                          <h5 className="tx-normal tx-rubik tx-color-03 mg-b-0"><small>250,000</small></h5>
                        </div>

                        <div className="progress ht-4 mg-b-0 op-5">
                          <div className="progress-bar bg-orange wd-45p" role="progressbar" aria-valuenow={65} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>

                      </div>

                      <div className="col-sm-6 col-lg-12 mg-t-30 mg-sm-t-0 mg-lg-t-30">

                        <div className="d-flex align-items-center justify-content-between mg-b-5">
                          <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Crianças</h6>
                          <span className="tx-10 tx-color-04">65% comparado a</span>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mg-b-5">
                          <h5 className="tx-normal tx-rubik lh-2 mg-b-0">13,596</h5>
                          <h6 className="tx-normal tx-rubik tx-color-03 lh-2 mg-b-0">20,000</h6>
                        </div>
                        <div className="progress ht-4 mg-b-0 op-5">
                          <div className="progress-bar bg-pink wd-40p" role="progressbar" aria-valuenow={20} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-12 mg-t-30 mg-sm-t-0 mg-lg-t-30">
                        <div className="d-flex align-items-center justify-content-between mg-b-5">
                          <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Outros</h6>
                          <span className="tx-10 tx-color-04">65% comparado a</span>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mg-b-5">
                          <h5 className="tx-normal tx-rubik lh-2 mg-b-0">28.50</h5>
                          <h6 className="tx-normal tx-rubik tx-color-03 lh-2 mg-b-0">20,000</h6>
                        </div>
                        <div className="progress ht-4 mg-b-0 op-5">
                          <div className="progress-bar bg-primary wd-85p" role="progressbar" aria-valuenow={65} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>
                      </div>

                    </div>
                    
                  </div>

                </div>
              </div>
            </div>
          </div>
  );
};

export default PerfilGenero;

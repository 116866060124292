import React, { useEffect, useState } from "react";

type Option = {
  cpf: string;
  nome: string;
  sobrenome: string;
};

type CheckBoxListProps = {
  options: Option[];
  onchange: (selected: Option[]) => void;
  multiple?: boolean;
};

const CheckBoxList: React.FC<CheckBoxListProps> = ({ options = [], onchange, multiple = false }) => {
  const [selected, setSelected] = useState<Option[]>([]);

  const toggle = (item: Option) => {
    const index = selected.findIndex((i) => i.cpf === item.cpf);
    let arrSelecteds = [...selected];

    if (index !== -1) {
      arrSelecteds.splice(index, 1);
    } else {
      arrSelecteds = multiple ? [...arrSelecteds, item] : [item];
    }
    setSelected(arrSelecteds);
  };

  useEffect(() => {
    onchange(selected);
  }, [selected, onchange]);

  return (
    <div style={{ height: "70%", overflowY: "auto" }}>
      {options.map((op, index) => {
        const isSelected = selected.findIndex((i) => i.cpf === op.cpf) !== -1;
        return (
          <div key={index} style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 15,
              width: "100%",
              borderBottom: "1px solid #C7C7C7",
            }}>

            <div style={{ marginLeft: 15 }}>
              <p style={{
                fontSize: 15,
                color: "#052937",
                textTransform: "capitalize",
                margin: 0,
              }}>
                {op.nome} {op.sobrenome}
              </p>
              <p style={{
                fontSize: 11,
                color: "#6D6D6D",
                margin: 0,
              }}>
                {op.cpf}
              </p>
            </div>
            
            <button
              onClick={() => toggle(op)}
              style={{
                height: 30,
                width: 80,
                borderRadius: 4,
                backgroundColor: isSelected ? "#28a745" : "#02679D",
                color: "#fff",
                border: "none",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
              disabled={isSelected}
            >
              {isSelected ? "Adicionado" : "Adicionar"}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default CheckBoxList;

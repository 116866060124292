// App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './Redux/store/store';
import HomePage from './pages/HomePage';
import { Visitantes } from './components/dashboard/visitantes';
import Layout from './components/layout/layout';
import Tab from './components/Tab';
import LoginPage from './pages/LoginPage';
import PrivateRoute from './Services/PrivateRoute';
import { AuthProvider } from './Auth/AuthContext';
import Notificacao from './pages/NotificacaoPage';
import Perfil from './pages/PerfilPage';
import QrCode from './pages/QrcodePage';

import ListAtividadeEmpresa from './components/atividades/listAtividadeEmpresa';
import AtividadeEmAndamento from './components/atividades/atividadesEmAndamento';
import RelatorioDeAtividades from './components/atividades/relatorioAtividades';
import AtividadeVisitantes from './components/atividades/atividadeVisitantes';
import QRCodeReader from './components/qrCode';
import TermoAtividades from './components/termos/TermosAtividades';



const App: React.FC = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<Layout />}>
            
              <Route index element={<PrivateRoute element={<HomePage />} />} />
              <Route path='/home' element={<PrivateRoute element={<HomePage />} />} />
              <Route path="/visitantes" element={<PrivateRoute element={<Visitantes />} />} />

              <Route path="/Empresa/" element={<PrivateRoute element={<Tab />} />} >
                
                <Route path="Atividades" element={<PrivateRoute element={<ListAtividadeEmpresa />} />} />
                <Route path="Atividades/Empresa" element={<PrivateRoute element={<AtividadeEmAndamento />} /> } />
                <Route path="Atividades/Empresa/Relatorio" element={<PrivateRoute element={<RelatorioDeAtividades />} /> } />
                <Route path="Atividades/Empresa/Visitantes" element={<PrivateRoute element={<AtividadeVisitantes />} /> } />
                <Route path='Atividades/Empresa/TermoAtividades' element={<PrivateRoute element={<TermoAtividades />} /> } />
                <Route path='Notificacao' element={<PrivateRoute element={<Notificacao />} /> } />
                <Route path='Perfil' element={<PrivateRoute element={<Perfil />} /> } />
                <Route path='Qrcode' element={<PrivateRoute element={<QRCodeReader />} /> } />
                

              </Route>

            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </Provider>
  );
};

export default App;

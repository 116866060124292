import React, { useState } from 'react';
import {useNavigate, useLocation, Outlet } from 'react-router-dom';
import InputText from '../components/Input/InputText';
import './styles.css';
import { useAuthContext } from '../Auth/useAuthContext';
import ListAtividadeEmpresa from '../components/atividades/listAtividadeEmpresa';


const QrCode: React.FC = () => {
  const { user } = useAuthContext();

  return (
    <div style={{ paddingTop: '20px' }}>

      <Outlet />

    </div>
  );
};

export default QrCode;

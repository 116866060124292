import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../../Auth/useAuthContext";
import InputText from "../../Input/InputText";
import Dropdown from "../../dropdown/Dropdown";
import api from "../../../Services/axios";
import { FormatMonth } from "../../../Services/formater";
import { BuscaDadosAtividade_By_user_id } from "../../../models/Atividades";
import ListaVerticalFiscalHistorico from "../../List/ListaVerticalHistorico";
import ListaVerticalActive from "../../List/ListaVerticalActive";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../Redux/store/store";
import { setPassageiros } from "../../../Redux/slices/atividadeSlice";

interface AtividadeData {
  json_result: {
    _id: string;
    ativa: number;
    duracao: string;
    empresa: {
      _id: string;
      cnpj: string;
      rasao_social: string;
    };
    usuario: {
      _id: string;
      nome: string;
    };
    veiculo: {
      nome: string;
      destino: string;
      capacidade: string;
    };
    dataInicio: string;
    passageiros: Passageiro[];
    destino_viagem: string;
  };
}

interface Passageiro {
  checkout?: boolean;
  idAtividade?: string;
}

interface Atividade {
  _id: string;
  titulo: string;
  hora: AtividadeData["json_result"][];
}

interface veiculo_response {
  _id: string;
  nome: string;
  value: string;
  label: string;
}

const AtividadeEmAndamento: React.FC = () => {
  const location = useLocation();
  const { id, empresa, nicho } = location.state as {
    id: string;
    empresa: string;
    nicho: string;
  };

  const { user } = useAuthContext();

  const [atividade, setAtividade] = useState<Atividade[]>([]);
  const [veiculo, setVeiculo] = useState("");
  const [duracao, setDuracao] = useState("");
  const [dataVeiculo, setDataVeiculo] = useState<veiculo_response[]>([]);
  const [turismo, setTurismo] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>();
  const dispatch = useDispatch<AppDispatch>();

  const [listaVisitantesCheckout, setListaVisitantesCheckout] = useState<Passageiro[]>([]);

  const listaDuracao = Array.from({ length: 12 }, (_, i) => ({
    id: i,
    label: `${i + 1} Hora${i > 0 ? "s" : ""}`,
    value: `${i + 1} Hora${i > 0 ? "s" : ""}`,
  }));

  useEffect(() => {
    onBuscarVeiculos(id);
    if (user) onBuscaAtividades(user.id);
    setTurismo(
      ![
        "Hospedagem",
        "Alimentício",
        "Ponto Turístico",
        "Restaurante",
        "Atrativo Turístico",
      ].includes(nicho)
    );
  }, [id, user, nicho]);

  // useEffect(() => {
  //     dispatch(setPassageiros(atividade));
  // }, [atividade]);

  async function onBuscaAtividades(user_id: string) {
    try {
      const data = await BuscaDadosAtividade_By_user_id(user_id);
      processarAtividades(data, id);
    } catch (err) {
      console.log("Falha ao obter dados da atividade.");
    }
  }

  function processarAtividades(data: AtividadeData[], empresaId: string) {
    if (data) {
      setAtividade([]);
      setListaVisitantesCheckout([]);

      const aux: Record<string, AtividadeData["json_result"][]> = data.reduce(
        (acc, item) => {
          if (item.json_result.empresa._id === String(empresaId)) {
            item.json_result.passageiros.forEach((element) => {
              if (!element.checkout) {
                element.idAtividade = item.json_result._id;
                setListaVisitantesCheckout((prev) => [...prev, element]);
              }
            });

            const datainicio = FormatMonth(item.json_result.dataInicio);
            if (!acc[datainicio]) acc[datainicio] = [];
            acc[datainicio].push(item.json_result);
          }
          return acc;
        },
        {} as Record<string, AtividadeData["json_result"][]>
      );

      for (const item in aux) {
        setAtividade((atividade) => [
          ...atividade,
          {
            _id: item,
            titulo: item,
            hora: aux[item],
          },
        ]);
      }
    }
  }

  async function onBuscarVeiculos(id: string) {
    try {
      const { data: res } = await api.get(`/veiculo/${id}`);
      if (res.veiculos) {
        const novosVeiculos = res.veiculos.map((item: veiculo_response) => ({
          value: item._id,
          label: item.nome,
        }));
        setDataVeiculo(novosVeiculos);
      }
    } catch (error) {
      console.log("Erro ao listar veiculos.", error);
    }
  }

  async function onSalvarAtividade() {
    if (turismo && (!veiculo || !duracao)) {
      setMessage(
        veiculo
          ? "Preencha a duração do roteiro!"
          : "Selecione ou solicite o cadastro do veículo/embarcação!"
      );
      return;
    }

    if (user) {
      setMessage("");
      try {
        const res = await api.post("/atividade", {
          usuario: user.id,
          empresa: id,
          veiculo: veiculo || 0,
          duracao: duracao || "0",
        });
        setMessage(res.data.message);
        if (user) onBuscaAtividades(user.id);
      } catch (error) {
        if (error)
          setMessage("Ainda existe atividades em aberto para o Colaborador!");
      }
    }
  }

  function SetaDropdow(item: string, local: string) {
    if (local === "Empresa") {
      onBuscarVeiculos(item);
      setTurismo(
        ![
          "Hospedagem",
          "Alimentício",
          "Ponto Turístico",
          "Restaurante",
          "Atrativo Turístico",
        ].includes(nicho)
      );
    } else if (local === "Veiculo") {
      setVeiculo(item);
    } else if (local === "Duracao") {
      setDuracao(item);
    }
  }

  return (
    <div className='containerbody2' style={{paddingTop: '20px',}}>
      <div>
        <InputText
          id="Empresa"
          type="text"
          title="Nome da empresa"
          placeholder="Nome da empresa"
          value={empresa ? `${id} - ${empresa}` : "Empresa"}
          onchange={() => console.log("Nome da empresa")}
          disabled
        />

        {turismo && (
          <div className="container_coluna">
            <Dropdown
              title="Veículo/Embarcação"
              data={dataVeiculo}
              placeholder="Selecione o veiculo/embarcação"
              onchange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                SetaDropdow(e.target.value, "Veiculo")
              }
              value={veiculo}
              msnWarning="Campo obrigatório."
              required
            />
            <Dropdown
              title="Duração do roteiro"
              data={listaDuracao}
              placeholder=""
              onchange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                SetaDropdow(e.target.value, "Duracao")
              }
              value={duracao}
              msnWarning="Campo obrigatório."
              required
            />
          </div>
        )}

        <div
          style={{
            color: "red",
            fontWeight: "bold",
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
          }}
        >
          {message}
        </div>
      </div>

      <div style={{display: 'flex', justifyContent: 'center',}}>
        {(user?.segmento === "Colaborador" || user?.admin) && (
          <button className="btncadastrar" onClick={onSalvarAtividade}>
            Iniciar Atividade
          </button>
        )}
      </div>

      <div
        style={{
          margin: "1rem",
          fontSize: "1.6rem",

          paddingTop: "10px",
          fontFamily: "Poppins-semiBold",
          color: "#222222",
        }}
      >
        <div>Registro de atividades</div>
        {atividade && <ListaVerticalActive data={atividade} type="Atividade" />}
      </div>
    </div>
  );
};

export default AtividadeEmAndamento;

import React, { useEffect, useState } from "react";
import InputText from "../Input/InputText";
import "./styles.css";
import { useAuthContext } from "../../Auth/useAuthContext";
import { FaBuilding, FaBandAid, FaUsers } from "react-icons/fa";
import ListaAtividades from "../List/ListAtividades";
import { useNavigate, useLocation } from "react-router-dom";
import { BuscaDadosEmpresa_By_user_id } from "../../models/Empresa";
import { BuscaQtdAtividade } from "../../models/Atividades";

interface Empresa {
  _id: string;
  rasao_social: string;
  nicho_mercado: string;
  cnpj: string;
}

interface EmpresaResponse {
  _id: string;
  colaborador_data: {
    empresa: {
      _id: string;
      rasao_social: string;
      nicho_mercado: string;
      atividade: string;
    };
  };
}

const ListAtividadeEmpresa: React.FC = () => {
  const { user } = useAuthContext();
  const [pesquisa, setPesquisa] = useState("");
  const [empresa, setEmpresa] = useState<Empresa[]>([]);
  const [filteredEmpresas, setFilteredEmpresas] = useState<Empresa[]>([]);
  const [QtdAtividade, setQtdAtividade] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchdata = async () => {
      if (user) {
        const data = await BuscaDadosEmpresa_By_user_id(user.id);

        if (data) {
          const novasEmpresas = await data.map((item: EmpresaResponse) => ({
            _id: item.colaborador_data.empresa._id,
            rasao_social: item.colaborador_data.empresa.rasao_social,
            nicho_mercado: item.colaborador_data.empresa.nicho_mercado,
            cnpj: item.colaborador_data.empresa.atividade,
          }));
          setEmpresa(novasEmpresas);
          setFilteredEmpresas(novasEmpresas);
          
        }
      }
    };
    fetchdata();
  }, [user]);

  useEffect(() => {

      const fetchdata = async () => {
        if(empresa.length > 0){
          try{
            const  qtdAtividade  = await BuscaQtdAtividade(empresa[0]._id); 
            setQtdAtividade(qtdAtividade);
          }catch(e){
            console.error(e);
          }
          
        }
      };
      fetchdata();

  }, [empresa]);

  const handleSearch = (text: string) => {
    setPesquisa(text);

    const filtered = empresa.filter((empresaItem) =>
      empresaItem.rasao_social.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredEmpresas(filtered);
  };

  const handleClick = (item: Empresa) => {
    const currentPath = location.pathname;
    navigate(`${currentPath}/Empresa/`, {
      state: {
        id: item._id,
        empresa: item.rasao_social,
        nicho: item.nicho_mercado,
      },
    });
  };

  return (
    <div style={{ paddingTop: "20px" }}>
      <div className="containerbody">
        <InputText
          id="pesquisar"
          type="text"
          title=""
          placeholder="Buscar Empresa"
          onchange={(e) => handleSearch(e.target.value)}
          value={pesquisa}
        />
      </div>

      <div
        style={{
          fontSize: "15px",
          fontWeight: "bold",
          fontFamily: "Poppins-Regular",
          color: "#333333",
          justifyItems: "center",
        }}
      >
        Informações das Atividades
      </div>

      <div style={{gap:'1rem', display:'flex'}}>


        <div
          style={{
            border: "1px solid #c7c7c7",
            borderRadius: "25px",
            padding: "2px",
            paddingTop: "0px",
            paddingBottom: "0px",
            /*backgroundColor: '#0b92a7'*/ justifyItems: "center",
            marginTop: "2rem",
            width: "100%", 
            
          }}
        >
          <div
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "15px",
              textAlign: "center",
             
            }}
          >
            <div>
              <FaBuilding size={34} style={styles.icon} />
              <div style={{ fontSize: "10px", fontFamily: "Poppins-semiBold" }}>
                Total de Empresas
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontFamily: "Poppins-semiBold",
                  color: "#E61E4D",
                }}
              >
                {filteredEmpresas.length}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            border: "1px solid #c7c7c7",
            borderRadius: "25px",
            padding: "2px",
            paddingTop: "0px",
            paddingBottom: "0px",
            /*backgroundColor: '#0b92a7'*/ justifyItems: "center",
            marginTop: "2rem",
            width: "100%", 
          }}
        >
          <div
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "15px",
              textAlign: "center",
            }}
          >
            <div>
              <FaBandAid size={34} style={styles.icon} />
              <div style={{ fontSize: "10px", fontFamily: "Poppins-semiBold" }}>
                Total de Atividades
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontFamily: "Poppins-semiBold",
                  color: "#E61E4D",
                }}
              >
                {QtdAtividade}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            border: "1px solid #c7c7c7",
            borderRadius: "25px",
            padding: "2px",
            paddingTop: "0px",
            paddingBottom: "0px",
            /*backgroundColor: '#0b92a7'*/ justifyItems: "center",
            marginTop: "2rem",
            width: "100%", 
          }}
        >
          <div
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "15px",
              textAlign: "center",
            }}
          >
            <div>
              <FaUsers size={34} style={styles.icon} />
              <div style={{ fontSize: "10px", fontFamily: "Poppins-semiBold" }}>
                Total de Clientes
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontFamily: "Poppins-semiBold",
                  color: "#E61E4D",
                }}
              >
                300
              </div>
            </div>
          </div>
        </div>

      </div>

      <div
        style={{
          margin: "1rem",
          fontSize: "15px",
          fontWeight: "bold",
          paddingTop: "10px",
          fontFamily: "Poppins-Regular",
          color: "#222222",
        }}
      >
        Empresas que estou cadastrado
      </div>

      {filteredEmpresas && (
        <ListaAtividades
          atividades={filteredEmpresas}
          onItemClick={handleClick}
        />
      )}
    </div>
  );
};

const styles = {
  icon: {
    color: "#222222",
    size: "20rem",
  },
};

export default ListAtividadeEmpresa;

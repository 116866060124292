import "./Dropdown.css";
import warning from "../../assets/image/warning.png";
const Dropdown = ({ title,data, placeholder, onchange, value, msnWarning, required}) => {
    
    return (
        <section className="box">
            <div className="textInput">{title}</div>
            <select required={required} className="dropdown" onChange={onchange} value={value}>
                <option value="" disabled selected>{placeholder}</option>

                {data.map((item) => {
                    return <option className="option" key={item.id} value={item.value}>{item.label}</option>
                })}

            </select>
            {/* <div className="warning">
                <img src={warning} alt="ícone de alerta" />
                {msnWarning}
            </div> */}
        </section>
    )
}

export default Dropdown;

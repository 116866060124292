import React, { ChangeEvent } from "react";
import "./InputText.css";
import { FaInfoCircle } from "react-icons/fa";

// Definindo as props que o InputText vai receber
interface InputTextProps {
  id: string;
  title: string | number | any;
  type?: string;
  placeholder?: string;
  value: string;
  onchange: (event: ChangeEvent<HTMLInputElement>) => void; 
  msnWarning?: string;
  required?: boolean;
  autocomplete?: string;
  disabled?: boolean;
}

const InputText: React.FC<InputTextProps> = ({
  id,
  type,
  title,
  placeholder,
  value,
  onchange,
  msnWarning,
  required = false,
  autocomplete,
  disabled = false,
}) => {
  return (
    <section className="box">
      <div className="textInput">{title}</div>
      <input
        id={id}
        required={required}
        className="input"
        type={type ? type : "text"}
        placeholder={placeholder}
        value={value}
        onChange={onchange}
        autoComplete={autocomplete} // Adicionando o atributo autocomplete
        disabled={disabled}
      />
      {msnWarning && (
        <div className="warning">
          <FaInfoCircle size={20} />
          {msnWarning}
        </div>
      )}
    </section>
  );
};

export default InputText;

import React from 'react';
import { FaBuilding } from 'react-icons/fa';

interface Atividade {
  _id: string;
  rasao_social: string;
  nicho_mercado: string;
  cnpj: string;
}

interface ListaAtividadesProps {
  atividades: Atividade[];
  onItemClick: (item: Atividade) => void;
}

const ListaAtividades: React.FC<ListaAtividadesProps> = ({ atividades, onItemClick }) => {

  return (

    
    <div style={styles.listContainer }>
      {atividades.map((item) => (
        <div
          key={item._id}
          style={styles.empresaItem}
          onClick={() => onItemClick(item)} // Redireciona para a página de detalhes ao clicar
        >
          <FaBuilding size={24} style={styles.icon} />
          <div>
            <h3 style={styles.nome}>{item.rasao_social}</h3>
            <p style={styles.tipo}>{item.nicho_mercado}</p>
            <p style={styles.cnpj}>{item.cnpj}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  listContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '15px',
    marginTop: '20px',
    marginBottom: '20px',
 
  },
  empresaItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    border: '1px solid rgb(199, 199, 199)',
    borderRadius: '8px',
    backgroundColor: '#fff',
    cursor: 'pointer', // muda o cursor para indicar que o item é clicável
  
  },
  icon: {
    margin:'2rem',
    /*marginRight: '15px',*/
    color: '#222222',
  },
  nome: {
    color: '#333333',
    margin: '0',
    fontSize: '14px',
    /*fontWeight: 'bold',*/
    fontFamily: 'Poppins-semiBold',
  },
  tipo: {
    margin: '4px 0 0 0',
    fontSize: '11px',
    
    color: '#777777',
  },
  cnpj: {
    fontFamily: 'Poppins-semiBold',
    margin: '9px 0 0 0',
    fontSize: '11px',
    /*fontWeight: 'bold',*/
    color: 'rgb(0 186 209)',
  },
};

export default ListaAtividades;

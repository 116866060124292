import React, { useState } from "react";
import {
  FaCalendarAlt,
  FaCopy,
  FaBriefcase,
  FaUsers,
  FaRegClock,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FormatTime } from "../../Services/formater";
import { Atividade } from "../../Types/atividade";
import api from "../../Services/axios";
import ConfirmationModal from "../modal/ConfirmationModal";
import { useDispatch } from "react-redux";
import { setAtividade } from "../../Redux/slices/atividadesEmpresaSlice";

interface ListaVerticalActiveProps {
  data: Atividade[] | any[];
  type: "Atividade" | "Relatorio";
}

const ListaVerticalActive: React.FC<ListaVerticalActiveProps> = ({
  data,
  type,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [idAtividade, setAIdAtividade] = useState<string>();
  const dispatch = useDispatch();

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  //console.log("Lista atividade: " + JSON.stringify(data))

  if(!data) return <></>


  const handleConfirmAction = async () => {
    await api
      .put(`/atividade/finalizar/${idAtividade}`)
      .then((res) => {
        console.log(res.data.message);
        navigate(-1);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        navigate(-1);
      });

    handleCloseModal();
  };

  const RelatorioAtividade = async (item: Atividade) => {
    
    dispatch(setAtividade(item));

    navigate("/Empresa/Atividades/Empresa/Relatorio", {
      state: { data: item },
    });

  };

  const FinalizaAtividade = async (item: Atividade) => {
    console.log("FinalizaAtividade: ", item);
    setAIdAtividade(item._id);
    handleOpenModal();
  };

  const TermosAtividade = async (item: Atividade) => {
    console.log("TermosAtividade: ", item);
    navigate("/Empresa/Atividades/Empresa/TermoAtividades", {
      state: { idAtividade: item._id },
    });
  };
  return (
    <div
      style={{
        marginTop: "20px",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <ConfirmationModal
        message="Tem certeza que deseja finalizar esta atividade?"
        isOpen={isModalOpen}
        onConfirm={handleConfirmAction}
        onCancel={handleCloseModal}
      />
      {data.map((item) => (
        <div
          key={type === "Atividade" ? item.codigo : item._id}
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid rgb(199, 199, 199)",
            borderRadius: "8px",
            backgroundColor: "rgb(255, 255, 255)",
            cursor: "pointer",
            flexDirection: "column",
          }}
        >
          {type === "Relatorio" && (
            <>
              <span
                style={{
                  fontFamily: "Poppins-Regular",
                  padding: " 12px 25px 4px",
                  fontSize: "14px",
                  color: item.ativa === 1 ? "#222222" : "#F1416C",
                  fontWeight: 600,
                }}
              >
                {item.ativa === 1
                  ? "Atividade em andamento"
                  : "Atividade encerrada"}
              </span>

              <div
                style={{
                  width: "90%",
                  height: "1px",
                  backgroundColor: "#ccc",
                  opacity: 1,
                  margin: "0 auto",
                }}
              />
            </>
          )}

          <div
            key={type === "Atividade" ? item.codigo : item._id}
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100px",
              overflow: "hidden",
              padding: "8px",
            }}
          >
            {item.ativa === 1 && (
              <div
                onClick={() =>
                  navigate("/Empresa/Atividades/Empresa/Visitantes", {
                    state: { data: item },
                  })
                }
                style={{
                  height: "100%",
                  flex: 0.1,
                  backgroundColor: "#00BAD1",
                  cursor: "pointer",
                }}
              />
            )}

            {item.ativa === 0 && (
              <div
                // onClick={() => navigate('/colaborador/finalizar-atividade', { state: { data: item } })}
                onClick={() =>
                  console.log("/colaborador/finalizar-atividade", {
                    state: { data: item },
                  })
                }
                style={{
                  height: "100%",
                  flex: 0.1,
                  backgroundColor: "#FF4949",
                  cursor: "pointer",
                }}
              />
            )}

            <div
              onClick={() =>
                type === "Atividade"
                  ? RelatorioAtividade(item)
                  : navigate("/Empresa/Atividades/Empresa/Visitantes", {
                      state: { data: item },
                    })
              }
              style={{
                display: "flex",
                flex: 5,
                alignItems: "center",
                flexDirection: "row",
                cursor: "pointer",
                padding: "16px",
              }}
            >
              {type === "Atividade" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: "25px",
                  }}
                >
                  <FaCalendarAlt size={34} color={"#222222"} />
                </div>
              )}

              <div style={{ display: "flex", flexDirection: "column",  }}>
                {type === "Atividade" && (
                  <>
                    <h3
                      style={{
                        fontSize: "14px",
                        fontFamily: "Poppins-semiBold",
                        color: "#222222",
                      }}
                    >
                      {item.titulo}
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "4px",
                        gap: "8px",
                      }}
                    >
                      <FaCopy size={18} />
                      <span style={{ fontSize: "11px", color: "#00b8d4" }}>
                        {item.hora && item.hora.length > 1
                          ? item.hora.length + " Atividades"
                          : item.hora && item.hora.length + " Atividade"}
                      </span>
                    </div>
                  </>
                )}

                {type === "Relatorio" && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#222222",
                          fontFamily: "Poppins-semiBold",
                        }}
                      >
                        {item.empresa.rasao_social}
                      </span>
                    </div>

                    <span
                      style={{
                        fontSize: "11px",
                        fontFamily: "Poppins-light",
                        color: "#6a6a6a",
                      }}
                    >
                      Apresente QRCode aos Fiscais
                    </span>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "4px",
                        gap: "100px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "4px",
                          gap: "8px",
                        }}
                      >
                        <FaRegClock size={20} />
                        <span
                          style={{
                            fontSize: "11px",
                            color: "#222222",
                            fontFamily: "Poppins-medium",
                          }}
                        >
                          {FormatTime(item.dataInicio || "") + "H"}
                        </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "4px",
                          gap: "8px",
                        }}
                      >
                        <FaUsers size={20} />
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#00bad1",
                          }}
                        >
                          {item.passageiros?.length}
                        </span>
                        <span
                          style={{
                            fontSize: "11px",
                            color: "#222222",
                            fontFamily: "Poppins-medium",
                          }}
                        >
                          Pessoas
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {type === "Relatorio" && item.ativa === 1 && (
            <>
              <div
                style={{
                  width: "90%",
                  height: "1px",
                  backgroundColor: "#ccc",
                  opacity: 1,
                  margin: "0 auto",
                }}
              />

              <div
                style={{
                  display: "flex",
                  flex: 2,
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 2,
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: 7,
                      height: 30,
                      width: "90%",
                      display: "flex", // Adicionado para centralizar o conteúdo
                      alignItems: "center", // Alinha verticalmente o conteúdo
                      justifyContent: "center", // Alinha horizontalmente o conteúdo
                      backgroundColor: item.ativa === 0 ? "#3498db" : "#222222",
                      cursor: "pointer",
                    }}
                    onClick={
                      item.ativa === 0 ? undefined : () => TermosAtividade(item)
                    }
                  >
                    <span
                      style={{
                        fontFamily: "Poppins-Regular",
                        color: "#fff",
                        fontSize: 14,
                      }}
                    >
                      {"Termos"}
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 2,
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: 7,
                      height: 30,
                      width: "90%",
                      display: "flex", // Adicionado para centralizar o conteúdo
                      alignItems: "center", // Alinha verticalmente o conteúdo
                      justifyContent: "center", // Alinha horizontalmente o conteúdo
                      backgroundColor: item.ativa === 0 ? "#3498db" : "#E61E4D",
                      cursor: "pointer",
                    }}
                    onClick={
                      item.ativa === 0
                        ? undefined
                        : () => FinalizaAtividade(item)
                    }
                  >
                    <span
                      style={{
                        fontFamily: "Poppins-Regular",
                        color: "#fff",
                        fontSize: 14,
                      }}
                    >
                      {"Finalizar"}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default ListaVerticalActive;

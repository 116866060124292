import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Atividade } from "../../../Types/atividade";
import ListaSimples from "../../List/ListaSimples";
import api from "../../../Services/axios";
import { useAuthContext } from "../../../Auth/useAuthContext";
import InputText from "../../Input/InputText";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store/store";


interface Passageiro {
  _id: string;
  nome: string;
  checkin: boolean;
  checkout: boolean;
  idViagem: number;
  sobrenome: string;
  identificacao: string;
  nacionalidade: string;
  emergencia_nome: string;
  emergencia_telefone: string;
  idAtividade?: string;
}

interface Empresa {
  _id: string;
  cnpj: string;
  rasao_social: string;
}

interface Usuario {
  _id: string;
  nome: string;
}

interface Veiculo {
  nome: string;
  destino: string;
  capacidade: string;
}

interface Data {
  _id: string;
  ativa: number;
  duracao: string;
  empresa: Empresa;
  usuario: Usuario;
  veiculo: Veiculo;
  dataInicio: string;
  passageiros: Passageiro[];
  destino_viagem: string;
}

const AtividadeVisitantes: React.FC = () => {
  const location = useLocation();
  const { data } = location.state as { data: Atividade };
  const  {showSnackbar, user} = useAuthContext();
  const [pesquisa, setPesquisa] = useState('');
  const [userFilter, setUserFilter] = useState<Passageiro[]>([]);

  //const passageiros = useSelector((state: RootState) => state.atividade);

  const [updatePassageiro, setUpdatePassageiro] = useState<Passageiro[]>(
    data.passageiros
  );
  
  const ShowSnackbar = (mensagem: string) => {
      if(mensagem)
      showSnackbar(mensagem);

  };

  useEffect(() => {
    setUpdatePassageiro(data.passageiros);
  },[])

  const handleSearch = (text: string) => {
    setPesquisa(text);
  
    if (text === '') {
      // Se o campo de pesquisa estiver vazio, exibe todos os passageiros
      setUpdatePassageiro(data.passageiros);
    } else {
      // Filtra os passageiros pelo nome completo (nome + sobrenome)
      const filtered = data.passageiros.filter((userItem) =>
        `${userItem.nome}${userItem.sobrenome}`.toLowerCase().includes(text.toLowerCase())
      );
      setUpdatePassageiro(filtered);
    }
  };
  
  // Função para atualizar o checkin do passageiro
  function updatePassageiroCheckin(data: Atividade, passengerId: string) {
    const updatedPassageiros = data.passageiros.map((passageiro) =>
      passageiro._id === passengerId
        ? { ...passageiro, checkin: true }
        : passageiro
    );
    setUpdatePassageiro(updatedPassageiros);
    return updatedPassageiros;
  }


  // Função para atualizar o checkout do passageiro
  function updatePassageiroCheckout(data: Atividade, passengerId: string) {
    const updatedPassageiros = data.passageiros.map((passageiro) =>
      passageiro._id === passengerId
        ? { ...passageiro, checkout: true }
        : passageiro
    );
    setUpdatePassageiro(updatedPassageiros);
    return updatedPassageiros;
  }

  async function removePassageiroCheckout(data: Atividade, passengerId: string) {
    const updatedPassageiros = data.passageiros.filter(
      (passageiro) => passageiro._id !== passengerId
    );
    
    setUpdatePassageiro(updatedPassageiros);
    return updatedPassageiros;
  }

  // Envia dados para realizar checkout do passageiro
  async function realizarCheckout(item: Passageiro) {
    let mensagem = "";
    if (item.checkin) {

      mensagem ="Checkout efetuado com sucesso!";
      updatePassageiroCheckout(data, item._id);
    } else {
      mensagem ="checkin efetuado com sucesso!";
      updatePassageiroCheckin(data, item._id);
    }

    //Enviar o pedido para o backend (descomentado caso necessário)
    await api.post("/atividade/checkout", {
      atividade: data._id,
      passageiro: item._id,
    }).then((response) => {
      console.log(response.data.message);
      ShowSnackbar(mensagem);
    }).catch((error) => {
      if(error.response.data.message)
        ShowSnackbar(error.response.data.message);
      console.log("Erro ao Ralizar Checkout.");
    });
  }

  async function removePassageiro(item: Passageiro) {

    let mensagem = "";

    const lista = await removePassageiroCheckout(data, item._id);

    if (user) {

      try {
        const response = await api.post(`/atividade/removepassageiros`, {
          listaPassageiros: lista,
          colaborador: user.id,
        });

        console.log(response);

        console.log("Removendo usuario: ", item);
        mensagem ="Usuario remvido com sucesso!";
        ShowSnackbar(mensagem);

      } catch (error: any) {
        console.error(error.response.data.message);
        ShowSnackbar(error.response.data.message);
      }
    }
  }

  return (
    <div className='containerbody2' style={{paddingTop: '20px',}}>
      <div>
        <div
          style={{
            
            fontSize: "15px",
            fontWeight: "bold",
            paddingTop: "10px",
            fontFamily: "Poppins-Regular",
            color: "#222222",
          }}
        >
          Conheça seus cliente!
        </div>
        <div
          style={{
            
            fontSize: "11px",
            fontWeight: "bold",
            fontFamily: "Poppins-Light",
            color: "#777777",
          }}
        >
          Ofereça a melhor experiência.
        </div>
        
      </div>

      <div style={{ paddingTop: "20px" }}>
      <InputText 
        id='pesquisar'
        type='text'
        title=''
        placeholder='Buscar passageiro'
        onchange={(e) => handleSearch(e.target.value)}
        value={pesquisa}
      />
      </div>

      <div
        style={{
          
          fontSize: "12px",
          fontWeight: "bold",
          paddingTop: "30px",
          fontFamily: "Poppins-Regular",
          color: "#222222",
        }}
      >
        <div>Quem irá efetuar Checkout?</div>
        {data && (
          <ListaSimples
            data={updatePassageiro}
            onItemClick={realizarCheckout}
            onRemoveItem={removePassageiro}
          />
        )}
      </div>
    </div>
  );
};

export default AtividadeVisitantes;

// features/atividade/atividadeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Empresa {
  _id: string;
  cnpj: string;
  rasao_social: string;
}

interface Usuario {
  _id: string;
  nome: string;
}

interface Veiculo {
  nome: string;
  destino: string;
  capacidade: string;
}

interface Passageiro {
  _id: string;
  checkout?: boolean;
  idAtividade?: string;
  nome?: string;
  sobrenome?: string;
  identificacao?: string;
}

interface AtividadeData {
  _id: string;
  ativa: number;
  duracao: string;
  empresa: Empresa;
  usuario: Usuario;
  veiculo: Veiculo;
  dataInicio: string;
  passageiros: Passageiro[];
  destino_viagem: string;
}

interface AtividadeState {
  atividade: AtividadeData | null;
}

const initialState: AtividadeState = {
  atividade: null,
};

const atividadeSlice = createSlice({
  name: 'atividade',
  initialState,
  reducers: {
    setPassageiros: (state, action: PayloadAction<Passageiro[]>) => {
      if (state.atividade) {
        state.atividade.passageiros = action.payload;
      }
    },
    addPassageiro: (state, action: PayloadAction<Passageiro>) => {
      if (state.atividade) {
        state.atividade.passageiros.push(action.payload);
      }
    },
    removePassageiro: (state, action: PayloadAction<string>) => {
      if (state.atividade) {
        state.atividade.passageiros = state.atividade.passageiros.filter(
          p => p._id !== action.payload
        );
      }
    },
    setAtividade: (state, action: PayloadAction<AtividadeData>) => {
      state.atividade = action.payload;
    },
  },
});

export const { setPassageiros, addPassageiro, removePassageiro, setAtividade } = atividadeSlice.actions;
export default atividadeSlice.reducer;

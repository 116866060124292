import axios from 'axios';
// config
import { HOST_API_KEY } from '../global-config';

// ----------------------------------------------------------------------

//externo
//const url = 'http://localhost:8001';
const url = 'https://sllips.com.br/api/';

const api = axios.create({ 
    baseURL: url,
    timeout:5000,
    timeoutErrorMessage:`Web Service não localizado!!! verificar.... URL: ${url}`

  });

api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default api;

import api from '../Services/axios';
import { useDispatch, useSelector } from 'react-redux';
import { setPassageiros } from '../Redux/slices/atividadeSlice';
import { AppDispatch, RootState } from '../Redux/store/store';




export const BuscaDadosAtividade_By_user_id = async ( id : string ) =>{

    const { data : res } = await api.get(`/atividade/${id}`);

    if(res){
        try{
            return res

        }catch(error){
                return "Falha ao listar Empresas tente mais tarde."
            }
    }

    return undefined;
    
}

export const BuscaQtdAtividade = async ( idEmpresa : string ) =>{

    const { data : res } = await api.get(`/atividade/qtd/${idEmpresa}`);

    if(res){
        try{

            return res.qtdAtividade

        }catch(error){
                return "Falha ao listar Empresas tente mais tarde."
            }
    }

    return undefined;
    
}
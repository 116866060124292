// store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../../Auth/authSlice';
import atividadeReducer from '../slices/atividadeSlice';
import atividadesEmpresaReducer from '../slices//atividadesEmpresaSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    atividade: atividadeReducer,
    atividadeEmpresa: atividadesEmpresaReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

import React, { useMemo } from 'react';
import { Chart, ChartOptions  } from 'react-charts';
import { curveNatural, curveBasis } from 'd3-shape'; 
const HistoricoVisitas: React.FC = () => {

    // Definindo os dados do gráfico
  const data = React.useMemo(
    () => [
      {
        label: 'Série 1',
        data: [
          { primary: 1, secondary: 2 },
          { primary: 2, secondary: 3 },
          { primary: 3, secondary: 5 },
          { primary: 4, secondary: 4 },
          { primary: 5, secondary: 7 },
        ],
      },
      {
        label: 'Série 2',
        data: [
          { primary: 1, secondary: 3 },
          { primary: 2, secondary: 4 },
          { primary: 3, secondary: 2 },
          { primary: 4, secondary: 5 },
          { primary: 5, secondary: 6 },
        ],
      },
    ],
    []
  );

  // Configurações do gráfico
  const options = React.useMemo<ChartOptions<any>>(
    () => ({
      data,
      primaryAxis: {
        getValue: (datum) => datum.primary,
      },
      secondaryAxes: [
        {
          getValue: (datum) => datum.secondary,
          elementType: 'line',
          curve: curveBasis
        },
      ],
    }),
    [data]
  );

    return (
        <div className="col-md-6 col-lg-5 mg-t-10">
            <div className="card">
              <div className="card-header pd-b-0 bd-b-0 pd-t-20 pd-lg-t-25 pd-l-20 pd-lg-l-25">
                <h6 className="mg-b-5">Histórico de Visitas</h6>
                <p className="tx-12 tx-color-03 mg-b-0">Trata de um registro das primeiras interações do visitante com o município, facilitando o acompanhamento do desenvolvimento desse relacionamento.</p>
              </div>
              <div className="card-body pd-sm-20 pd-lg-25">
                <div className="row row-sm">
                  <div className="col-sm-5 col-md-12 col-lg-6 col-xl-5">
                    <div className="media">
                      <div className="wd-40 ht-40 rounded bd bd-2 bd-primary d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-bar-chart-2 wd-20 ht-20 tx-primary stroke-wd-3"><line x1="18" y1="20" x2="18" y2="10"></line><line x1="12" y1="20" x2="12" y2="4"></line><line x1="6" y1="20" x2="6" y2="14"></line></svg>
                      </div>
                      <div className="media-body mg-l-10">
                        <h4 className="tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-5">16.524</h4>
                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-0">Visitantes de 1ª visita</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-5 col-md-12 col-lg-6 col-xl-5 mg-t-20 mg-sm-t-0 mg-md-t-20 mg-lg-t-0">
                    <div className="media">
                        <div className="wd-40 ht-40 rounded bd bd-2 bd-gray-500 d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#faa43a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-bar-chart-2 wd-20 ht-20 stroke-wd-3">
                                <line x1="18" y1="20" x2="18" y2="10"></line>
                                <line x1="12" y1="20" x2="12" y2="4"></line>
                                <line x1="6" y1="20" x2="6" y2="14"></line>
                            </svg>
                        </div>
                        <div className="media-body mg-l-10">
                        <h4 className="tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-5">6.325</h4>
                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-0">Visitantes de 2ª visita</p>
                        </div>
                    </div>
                  </div>

                </div>

                <div className="chart-eight">

                  <div className="flot-chart" style={{
                        height: '200px',
                    }}>

                  <Chart options={options} />

                  </div>

                </div>
              </div>
            </div>
          </div>
    )
}

export default HistoricoVisitas;
import React, { useState } from 'react';
import {useNavigate, useLocation, Outlet } from 'react-router-dom';
import InputText from '../components/Input/InputText';
import './styles.css';
import { useAuthContext } from '../Auth/useAuthContext';
import ListAtividadeEmpresa from '../components/atividades/listAtividadeEmpresa';


const Atividades: React.FC = () => {
  const { user } = useAuthContext();

  return (
    <div style={{ paddingTop: '16px',}}>
      <div style={{ marginBottom:'2rem', paddingRight: 'auto' }}>
        <div style={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'Poppins-Regular', color: '#222222', }}>Bem vindo, {user?.nome}!</div>
        <div style={{ fontSize: '13px', fontFamily: 'Poppins-Regular', color: '#222222' }}>Vamos oferecer experiências incríveis!</div>
      </div>

      <Outlet />

    </div>
  );
};

export default Atividades;
